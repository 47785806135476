import { Service } from '@alpha-pai/base';
import {
  showPvText,
  showDateText,
  showPv,
  showSimpleDateText,
  dateFormat
} from '../utils';
import IndustryFormatter from '@/modules/reading/formatter/InterestIndunstry.js';

const formatCommentList = data => {
  return {
    total: data?.total || 0,
    tip: data?.tip || false,
    list: (data?.list || []).map(l => {
      const { date, pv } = l;
      return {
        ...l,
        title: l.title || '',
        name: l.analyst || '',
        institution: l.publishInstitution || '',
        hasRead: l.read || false,
        date: showSimpleDateText(date),
        fineDate: showDateText(date),
        pv: showPvText(pv),
        showPv: showPv(date) && pv,
        originalPv: pv
      };
    })
  };
};
const formatAnnouncementList = data => {
  return {
    total: data?.total || 0,
    tip: data?.tip || false,
    list: (data?.list || []).map(l => {
      const { stock = [], industry = [] } = l;
      return {
        ...l,
        title: l.title || '',
        time: l.publishTime,
        stock,
        industry
      };
    })
  };
};

const formatReportList = data => {
  return {
    total: data?.total || 0,
    tip: data?.tip || false,
    list: data?.list || []
  };
};

class ReadService extends Service {
  async getIndustryList(options = {}) {
    const { readDao } = this.dataflow.dao;
    const data = await readDao.getIndustryList(options);
    const list = data?.data || [];
    return list.map(item => ({
      value: item.id || '',
      label: item.name || ''
    }));
  }

  async getSummaryList(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getSummaryList(options);
    return this.getSummaryListFormat(res.data || {});
  }

  async getSummaryDetail(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getSummaryDetail(options);
    return this.getSummaryDetailFormat(res.data || {});
  }

  async getWeChatList(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getWeChatList(options);
    return this.getWeChatListFormat(res.data || {});
  }

  async peopleWatch(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.peopleWatch(options);
    let list = res?.data || [];
    if (list.length > 3) {
      list = list.slice(0, 3);
    }
    return this.peopleWatchFormat(list);
  }

  async getWeChatDetail(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getWeChatDetail(options);
    return this.getWeChatDetailFormat(res?.data);
  }

  async getFav(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getFav(options);
    const list = res?.data || [];
    return list.map(item => ({
      value: item.industryId || '',
      label: item.industryName || ''
    }));
  }

  async getFavList(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getFavList(options);
    return (res?.data || []).map(r => ({
      title: r.title,
      num: showPvText(r.pv),
      id: r.id,
      sourceId: r.sourceId,
      feature: r.feature
        ? r.feature.filter(x => x !== '机构观点').filter(x => x !== '产业资讯')
        : [],
      author: {
        id: r.sourceId,
        name: r.sourceName,
        url: r.url,
        industryName: r.industry,
        institution: r.institution,
        rank: r.rank
      },
      date: showDateText(r.publishDate)
    }));
  }

  async getMeetingRightList(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getMeetingRightList(options);
    return (res?.data || []).map(r => ({
      title: r.title,
      num: showPvText(r.pv),
      id: r.id,
      feature: r.feature,
      stock: r.stock,
      guest: r.guest,
      industry: r.industry,
      author: {
        id: r.sourceId,
        name: r.sourceName,
        url: r.url,
        industryName: r.industry,
        institution: r.institution
      },
      date: showDateText(r.date)
    }));
  }

  async saveFav(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.saveFav(options);
    return res;
  }

  async follow(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.follow(options);
    return res;
  }

  async getInstitutionEmail(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getInstitutionEmail(options);
    return res?.data || 0;
  }

  async searchInstitutionEmail(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.searchInstitutionEmail(options);
    return res?.data || 0;
  }

  async checkReadInfo(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.checkReadInfo(options);
    return res?.data || 0;
  }

  async getTag(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getTag(options);
    return res?.data;
  }

  async getInterestValues(options) {
    const { readDao } = this.dataflow.dao;
    const { data } = await readDao.getInterestValues(options);
    return IndustryFormatter.handleAllInterestIndustry(data);
  }

  async smartSearch(options) {
    const { readDao } = this.dataflow.dao;
    const { data } = await readDao.smartSearch(options);
    const {
      // 机构观点搜索结果
      // wechatArticleInstitution,

      // 研报 新旧替换 研报改版，换成report
      report,
      // 产业资讯搜索结果
      wechatArticleIndustry,
      // 会议速记搜索结果
      roadshowSummary,
      // // 公众号搜索结果
      wechatArticleAccount,
      // 股票搜索结果
      stock,
      // 评论搜索结果
      comment,
      // 公告搜索结果
      announcement,
      // 研报图表
      figure,
      // 公司调研
      company,
      // 系列调研
      investigation,
      // 板块
      sector
    } = data || {};
    const pointResult = formatReportList(report);
    const newsResult = this.getWeChatListFormat(wechatArticleIndustry);
    const summaryResult = this.getSummaryListFormat(roadshowSummary);
    const commentResult = formatCommentList(comment);
    const announcementResult = formatAnnouncementList(announcement);
    const publicAccountResult = this.getPublicAccountFormat(
      wechatArticleAccount
    );
    return {
      pointResult,
      newsResult,
      summaryResult,
      stock,
      publicAccountResult,
      commentResult,
      announcementResult,
      companyResult: company,
      investigationResult: investigation,
      figure,
      sector
    };
  }

  async searchSummary(options) {
    const { readDao } = this.dataflow.dao;
    const { data } = await readDao.searchSummary(options);
    const { ReadingSearchListResponse } = this.dataflow.formatters;
    return new ReadingSearchListResponse({ ...data, type: options.data.type });
  }

  async wechatArticleSearch(options) {
    const { readDao } = this.dataflow.dao;
    const { ReadingSearchListResponse } = this.dataflow.formatters;
    const { data } = await readDao.wechatArticleSearch(options);
    return new ReadingSearchListResponse({ ...data, type: options.data.type });
  }

  async wechatSearch(options) {
    const { readDao } = this.dataflow.dao;
    const { PublicAccountListResponse } = this.dataflow.formatters;
    const { data } = await readDao.wechatSearch(options);
    return new PublicAccountListResponse(data);
  }

  getSummaryListFormat(data) {
    // *************
    return {
      total: data?.total || 0,
      tip: data?.tip || false,
      list: (data?.list || []).map(l => {
        const { date, pv } = l;
        return {
          ...l,
          // eslint-disable-next-line
          isSpecialist: (l.feature || []).indexOf('专家路演') !== -1,
          // eslint-disable-next-line
          isManager: (l.feature || []).indexOf('高管参会') !== -1,
          title: l.title || '',
          name: l.analyst || '',
          institution: l.publishInstitution || '',
          industrys: (l.industry || []).map(i => i.name),
          stocks: (l.stock || []).map(i => i.name),
          time: l.date,
          hasRead: l.read || false,
          date: showDateText(date),
          fineDate: showDateText(date),
          pv: showPvText(pv),
          showPv: showPv(date) && pv,
          originalPv: pv
        };
      })
    };
  }

  getPublicAccountFormat(data) {
    return {
      total: data?.total | 0,
      tip: data?.tip || false,
      list: (data?.list || []).map(l => {
        return {
          ...l,
          instName: l.institutionName || ''
        };
      })
    };
  }

  getSummaryDetailFormat(data) {
    return {
      ...data,
      title: data.title || '',
      isFavorite: data?.isFavorite || false,
      industrys: (data.industry || []).map(i => i.name),
      stockTags: (data.stock || []).map(i => i.name),
      readDataInfo: {
        count: data.content ? data.wordCount : data?.aiSummary?.wordCount,
        timeLong: data.content
          ? `${data.duration || 1}分钟`
          : `${data?.aiSummary?.duration || 1}分钟`
      },
      roadShowInfo: {
        speaker: [data.analyst],
        // 注：date 文章日期、roadshowDate 路演日期
        time: data.roadshowDate
          ? dateFormat(data.roadshowDate, 'YYYY-MM-DD')
          : '',
        publishTime: data.date ? dateFormat(data.date, 'YYYY-MM-DD') : ''
      },
      html:
        data.content ||
        (data?.aiSummary?.content
          ? `<div>${data?.aiSummary?.content}</div>`
          : '') ||
        ''
    };
  }

  getWeChatListFormat(data) {
    return {
      total: data?.total || 0,
      list: (data?.list || []).map(l => ({
        ...l,
        title: l?.title,
        sourceId: l?.sourceId,
        industrys: (l.industry || []).map(i => i.name),
        stocks: (l.stock || []).map(i => i.name),
        time: l?.date || '',
        avatar: l.url,
        name: l?.sourceName || '',
        read: showPvText(l.pv),
        hasRead: l.read || false,
        featureHas: l.feature,
        feature: l.feature
          ? l.feature
              .filter(x => x !== '机构观点')
              .filter(x => x !== '产业资讯')
          : []
      })),
      tip: data?.tip
    };
  }

  peopleWatchFormat(data) {
    return data.map(d => ({
      id: d.id,
      title: d.title || '',
      industry: d?.industry?.name || '',
      read: showPvText(d.pv)
    }));
  }

  getWeChatDetailFormat(data) {
    let duration = '';
    if (data?.duration) {
      duration = `${data?.duration || 1}分钟`;
    }
    return {
      ...data,
      title: data.title || '',
      industrys: [],
      stockTags: (data.stock || []).map(i => i.name),
      isFavorite: data?.isFavorite || false,
      time: data.date ? dateFormat(data.date, 'YYYY-MM-DD') : '',
      featureHas: data.feature,
      feature: data.feature
        ? data.feature
            .filter(x => x !== '机构观点')
            .filter(x => x !== '产业资讯')
        : [],
      publicAccount: {
        account: data?.sourceName || '',
        count: showPvText(data.pv),
        id: data.id,
        time: data.date ? dateFormat(data.date, 'YYYY-MM-DD') : '',
        authors: data.author || [],
        icon: data?.url || '',
        industryName: data.industryName,
        industryCode: data.industryCode
      },
      readDataInfo: {
        count: data?.wordCount || 0,
        timeLong: duration
      },
      html: data?.content
    };
  }

  // 获取综合搜索分词
  async chatIntent(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.chatIntent(options);
    return res?.data;
  }

  async saveWord(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.saveWord(options);
    return res.data;
  }

  async searchSuggest(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.searchSuggest(options);
    return res.data;
  }

  async getViewpointDetail(options) {
    const { readDao } = this.dataflow.dao;
    const res = await readDao.getViewpointDetail(options);
    return res.data;
  }
}

export default ReadService;
