<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : luyue01@rabyte.cn
 * @Date          : 2023-11-10 14:53:39
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-04-07 13:41:55
 */
-->
<template>
  <div class="app-layout">
    <div class="app-layout-container">
      <div class="app-header"><app-header /></div>
      <div class="app-layout-body">
        <div class="app-left-side">
          <app-nav />
        </div>
        <div class="app-right-content">
          <keep-alive :include="cachedSessions" :max="20">
            <router-view :key="routerViewKey" />
          </keep-alive>
        </div>
      </div>
    </div>
    <SideAdvModal />
    <UserModal />
    <VipIntroductionModal
      :visible="isIntroModalVisible"
      @close="handleIntroductionModalClose"
    />
    <SideVerifyMobileModal />
    <ChannelUserModal />
    <!--    <EtfLaunchTip />-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { CommonUtil } from '@alpha-pai/core/utils';

import { InstitutionRoleEnums } from '@/common/config/enum';
import SideAdvModal from '@/common/components/side-adv-modal-pc';
import SideVerifyMobileModal from '@/common/components/side-verify-mobile-modal';
import UserModal from '@/common/components/web-user-modal';
import VipIntroductionModal from '@/common/components/vip-introduction-modal';
import ChannelUserModal from '@/common/components/channel-user-modal';

import AppHeader from './components/header/AppHeader';
import AppNav from './components/side-nav/AppNav';
import SetTracker from '@/common/utils/setTracker';
// import EtfLaunchTip from './components/guide/EtfLaunchTip';

export default {
  components: {
    AppHeader,
    AppNav,
    SideAdvModal,
    UserModal,
    ChannelUserModal,
    VipIntroductionModal,
    SideVerifyMobileModal
    // EtfLaunchTip
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      ui(state) {
        return state.ui || {};
      },

      themeConfig(state) {
        return state.themeConfig || {};
      },

      preference(state) {
        return state.preference || {};
      },

      cachedSessions(state) {
        return state.cachedSessions || [];
      }
    }),
    routerViewKey() {
      return this.$route.path;
    },
    isIntroModalVisible() {
      const { global, readingStore } = this.$store.state;
      return global.user?.type === InstitutionRoleEnums.purchasers
        ? readingStore.isIntroModalVisible
        : '';
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(user) {
        const isPurchasers = user?.type === InstitutionRoleEnums.purchasers;
        // 同登录 endofvip弹窗展示逻辑
        if (
          isPurchasers &&
          (user.productEnd ||
            user.personalEnd ||
            (user.cardAuthFlag !== 1 &&
              user.informationFlag === 1 &&
              user.upgradeFlag !== 1 &&
              !user.trialFlag &&
              user.productType === 0))
        ) {
          this.removeGlobalAppData().finally(() => {
            CommonUtil.userLogout();
            this.$router.replace({ path: '/login' });
          });
        }
      }
    }
  },
  mounted() {
    document.addEventListener('copy', this.handleCopy);
  },
  methods: {
    ...mapActions(['removeGlobalAppData']),
    handleIntroductionModalClose() {
      this.$store.commit('readingStore/setIsIntroModalVisible', '');
    },
    handleCopy() {
      const selection = window.getSelection();
      const copyText = selection.toString();
      const fullPath = this.$route.fullPath;
      if (copyText) {
        SetTracker('Web_Copy_Event', {
          copy_text: copyText,
          full_path: fullPath
        });
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('copy', this.handleCopy);
  }
};
</script>

<style scoped lang="scss">
.app-layout {
  min-width: 1280px;
  // min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  ::v-deep * {
    box-sizing: border-box;
  }
  .app-layout-container {
    height: 100%;
    .app-header {
      position: relative;
      z-index: 1999;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    }
    .app-layout-body {
      height: calc(100% - 50px);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .app-left-side {
        width: 58px;
        height: 100%;
        background: $theme-bg-1;
      }
      .app-right-content {
        width: calc(100% - 58px);
        height: 100%;
      }
    }
  }
}
</style>
