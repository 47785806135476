/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-16 10:34:36
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-09-24 19:05:54
 */
import { AuthStrategy } from '@alpha-pai/base';

/* *
 * 资源权限映射
 */
const RESOURCE_AUTH_MAP = [
  'AUTH_nav_home',
  '/',
  '/home',

  'AUTH_nav_examples',
  '/examples',
  '/examples/100071',
  '/examples/100072',

  'AUTH_nav_point',
  'AUTH_nav_news',
  'AUTH_nav_meeting',
  'AUTH_nav_my-focus',
  'AUTH_nav_research',
  'AUTH_nav_announcement',
  '/reading/home/point',
  '/reading/home/report',
  '/reading/home/public-roadshow',
  '/reading/home/news',
  '/reading/home/meeting',
  '/reading/home/comment/detail',
  '/reading/home/my-order',
  '/reading/home/convert-meeting',
  '/reading/home/my-focus',
  '/reading/home/research',
  '/reading/home/announcement',
  '/reading/home/stock',
  '/reading/home/comment',
  '/reading/home/segment',
  '/reading/home/etf',
  '/reading/home/etf/detail',
  '/reading/survey/detail',
  '/reading/home/point/detail',
  '/reading/home/point/official',
  '/reading/home/us-stock-point/detail',
  '/reading/home/news/detail',
  '/reading/home/news/official',
  '/reading/home/meeting/detail',
  '/reading/home/calendar',

  '/reading/profile',
  '/reading/search',
  '/reading/self-summary-detail',
  '/reading/translate-tool',
  '/reading/translate-preview',
  '/reading/foreign-market'
];

class SaasAdminAuthStrategy extends AuthStrategy {
  calculate() {
    const auth = this.dep.auth || [];
    return RESOURCE_AUTH_MAP.concat(...auth);
  }
}

export default SaasAdminAuthStrategy;
